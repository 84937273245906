<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="knowledge-base-page">
    <h1 class="text-primary mb-4 text-center">
      <strong>{{
        $t(resources.DownloadableResources.i18n) || resources.DownloadableResources.name
      }}</strong>
    </h1>
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in categories"
        :key="item.id"
        @click="goToDetail(item.id, item.name)"
      >
        <vx-card class="text-center cursor-pointer">
          <img
            :src="item.imageUrl"
            alt="graphic"
            class="responsive mx-auto mb-4"
          />
          <h4 class="mb-2">
            <strong>{{ item.name.toUpperCase() }}</strong>
          </h4>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      categories: []
    };
  },
  computed: {},
  async created() {
    await this.getType();
  },
  methods: {
    async getType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BlogType/GetByType/1/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.categories = [];
          } else {
            this.categories = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    goToDetail(blogTypeId, blogTypeName) {
      this.$router.push({
        name: "resources-marketing-detail",
        params: {
          blogTypeId: blogTypeId,
          blogTypeName: blogTypeName
        }
      });
    }
  }
};
</script>
