var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "knowledge-base-page" } }, [
    _c("h1", { staticClass: "text-primary mb-4 text-center" }, [
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.$t(_vm.resources.DownloadableResources.i18n) ||
              _vm.resources.DownloadableResources.name
          )
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "vx-row" },
      _vm._l(_vm.categories, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "vx-col w-full md:w-1/3 sm:w-1/2 mb-base",
            on: {
              click: function($event) {
                return _vm.goToDetail(item.id, item.name)
              }
            }
          },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("img", {
                staticClass: "responsive mx-auto mb-4",
                attrs: { src: item.imageUrl, alt: "graphic" }
              }),
              _c("h4", { staticClass: "mb-2" }, [
                _c("strong", [_vm._v(_vm._s(item.name.toUpperCase()))])
              ])
            ])
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }